import { Outlet } from "react-router-dom";

import { Container, Content } from "rsuite";

import { HeaderComponent } from "../components/Header";

export default function Layout() {
  return (
    <>
      <Container>
        <HeaderComponent />
        <Container>
          <Content>
            <Outlet />
          </Content>
        </Container>
        {/* <Container></Container> */}
      </Container>
    </>
  );
}
