import { Navbar, Header } from "rsuite";

export const HeaderComponent = () => {
  return (
    <>
      <Header>
        <Navbar>
          <Navbar.Brand>Kidsmood</Navbar.Brand>
        </Navbar>
      </Header>
    </>
  );
};
