import { useEffect, useState } from "react";
import { getAuth, signInWithEmailAndPassword, User } from "firebase/auth";

import "../assets/css/Login.scss";
import { Button, Container, Content, FlexboxGrid, Input, Panel } from "rsuite";

type Props = {
  children?: React.ReactNode;
};

const Authentification: React.FC<Props> = ({ children }) => {
  const [checked, setChecked] = useState(false);
  const [user, setUser] = useState<User | null>(null);

  useEffect(() => {
    const auth = getAuth();

    auth.onAuthStateChanged((user) => {
      setChecked(true);
      setUser(user);
    });
  }, []);

  if (!checked) return null; // TODO Implement Loading State

  if (!user) return <Login />;

  return <>{children}</>;
};

export default Authentification;

const Login: React.FC = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    console.log("submit");
    e.preventDefault();
    const auth = getAuth();

    signInWithEmailAndPassword(auth, email, password);
  };

  return (
    <Container>
      <Content>
        <FlexboxGrid justify="center" align="middle" style={{ height: "100%" }}>
          <FlexboxGrid.Item colspan={12}>
            <Panel header={<h3>Login</h3>} bordered>
              <form onSubmit={onSubmit}>
                <Input
                  required
                  type="email"
                  placeholder="Email"
                  value={email}
                  onChange={setEmail}
                />
                <Input
                  required
                  type="password"
                  placeholder="Passwort"
                  value={password}
                  onChange={setPassword}
                />

                <Button type="submit" block>
                  Login
                </Button>
              </form>
            </Panel>
          </FlexboxGrid.Item>
        </FlexboxGrid>
      </Content>
    </Container>
  );
};
