import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux/es/exports";
import { persistor, store } from "./redux";
import { PersistGate } from "redux-persist/integration/react";

import { initializeApp, getApps } from "firebase/app";
import { createBrowserRouter, RouterProvider } from "react-router-dom";

import Authentification from "./routes/Authentification";
import Layout from "./routes/Layout";
import Home from "./routes/Home";

// import "rsuite/dist/rsuite.min.css";
import "./assets/css/index.less";
import { CustomProvider } from "rsuite";

if (!getApps().length) {
  initializeApp({
    apiKey: process.env.REACT_APP_FIREBASE_API,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE,
    messagingSenderId: process.env.REACT_APP_FIREBASE_SENDER,
    appId: process.env.REACT_APP_FIREBASE_APP,
  });
}

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

const router = createBrowserRouter([
  {
    path: "/",
    element: <Layout />,
    errorElement: <div>404</div>,
    children: [
      {
        index: true,
        element: <Home />,
      },
    ],
  },
]);

root.render(
  <React.StrictMode>
    <PersistGate loading={null} persistor={persistor}>
      <Provider store={store}>
        <CustomProvider theme="dark">
          <Authentification>
            <RouterProvider router={router} />
          </Authentification>
        </CustomProvider>
      </Provider>
    </PersistGate>
  </React.StrictMode>
);
