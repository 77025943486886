import { useState } from "react";
import { Row, Col, Input, List, Panel, Button } from "rsuite";
import { FullEntry, useOrders } from "../components/hooks/useOrders";

export default function Home() {
  const [search, setSearch] = useState("");
  const [current, setCurrent] = useState<FullEntry | null>(null);

  const order = useOrders(search);

  const copyAddress = () => {
    const inp = document.getElementById("address") as HTMLInputElement;

    if (inp) {
      inp.focus();
      inp.setSelectionRange(0, 99999);

      navigator.clipboard.writeText(inp.value);
    }
  };

  return (
    <div>
      <Row gutter={20}>
        <Col xs={12}>
          <Input placeholder="Suche" value={search} onChange={setSearch} />

          <br />
          <List>
            {order.map((i) => {
              const onClick = () => setCurrent(i);
              return (
                <List.Item
                  key={i.id}
                  onClick={onClick}
                  style={{
                    backgroundColor: i.id === current?.id ? "#3c3c3c" : "",
                    paddingLeft: 20,
                  }}
                >
                  <Row>
                    <Col xs={18}>
                      <p>Name: {i.name}</p>
                      <p>Produkt: {i.product}</p>
                    </Col>
                    <Col xs={6}>
                      <p>Bestellung {i.order.id}</p>
                      <p>
                        {new Intl.DateTimeFormat("de-DE", {
                          month: "2-digit",
                          year: "numeric",
                          day: "2-digit",
                          hour: "2-digit",
                          minute: "2-digit",
                        }).format(i.date.toDate())}
                      </p>
                    </Col>
                  </Row>
                </List.Item>
              );
            })}
          </List>
        </Col>
        <Col xs={12}>
          {!!current && (
            <Panel header={`Bestellung ${current.order.id}`} bordered>
              <Row gutter={20}>
                <Col xs={20}>
                  <p>Adresse</p>
                  <Col xs={24}>{current.order.address.recipient}</Col>
                  <Col xs={24}>{current.order.address.street}</Col>
                  <Col xs={24}>
                    {current.order.address.zip} {current.order.address.city}
                  </Col>
                  <Col xs={24}>{current.order.address.country}</Col>
                </Col>
                <Col xs={4}>
                  <Button onClick={copyAddress}>Kopieren</Button>
                  <input
                    style={{ display: "none" }}
                    id="address"
                    type="text"
                    value={`${current.order.address.recipient} ${current.order.address.street}, ${current.order.address.zip} ${current.order.address.city} ${current.order.address.country}`}
                  />
                </Col>
              </Row>

              <br />

              <Row gutter={20}>
                <p>Produkte</p>
                <br />
                {current.order.products.map((i) => {
                  return (
                    <List.Item key={i.title}>
                      <p>Name: {i.name}</p>
                      <p>Produkt: {i.title}</p>
                    </List.Item>
                  );
                })}
              </Row>
            </Panel>
          )}
        </Col>
      </Row>
    </div>
  );
}
