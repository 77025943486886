import {
  collection,
  doc,
  CollectionReference,
  DocumentData,
  getFirestore,
  query,
  getDocs,
  orderBy,
  startAt,
  endAt,
  Timestamp,
  where,
} from "firebase/firestore";

export interface Entry {
  name: string;
  product: string;
  date: Timestamp;
  order: {
    id: string;
    address: {
      street: string;
      country: string;
      city: string;
      recipient: string;
      zip: string;
    };
    products: {
      name: string;
      title: string;
    }[];
  };
}

// This is just a helper to add the type to the db responses
const createCollection = <T = DocumentData>(collectionName: string) => {
  const firestore = getFirestore();
  return collection(firestore, collectionName) as CollectionReference<T>;
};

export const createFirestoreKey = () => doc(createCollection("random")).id;

export const entryCol = () => createCollection<Entry>("orders");

export const getOrders = (search: string) => {
  const now = new Date();
  now.setDate(now.getDate() - 1);
  now.setHours(0);
  now.setMinutes(0);

  const q = query(
    entryCol(),
    orderBy("date"),
    where("date", ">=", now)
    // orderBy("name"),
    // startAt(search),
    // endAt(`${search}\uf8ff`)
  );

  return getDocs(q);
};
