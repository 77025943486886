import { useEffect, useState } from "react";
import { Entry, getOrders } from "../../lib/firestore";

export interface FullEntry extends Entry {
  id: string;
}

export const useOrders = (search: string) => {
  const [result, setResult] = useState<FullEntry[]>([]);

  useEffect(() => {
    if (search.length < 2) {
      setResult([]);
      return;
    }

    getOrders(search).then((res) => {
      const tmp: FullEntry[] = [];

      console.log(res.docs);

      res.forEach((i) => {
        const data = i.data();

        if (data.name.toUpperCase().includes(search.toUpperCase())) {
          tmp.push({
            ...data,
            id: i.id,
          });
        }
      });

      setResult(tmp);
    });
  }, [search]);

  return result;
};
