import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface UserState {
  email: string;
  uid: string;
}

const initialState: UserState = {
  email: "",
  uid: "",
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    updateUser: (_, action: PayloadAction<UserState>) => {
      return action.payload;
    },
    logout: () => {
      return initialState;
    },
  },
});

export const { updateUser, logout } = userSlice.actions;

export default userSlice.reducer;
